import { version } from '../../package.json';

const TITLE = '';

export default {
  LOGIN: `${TITLE} Login`,
  SIGNUP: `${TITLE} Sign up`,
  RESET_PASSWORD: `${TITLE} Reset password`,
  BASE_URL: process.env.PUBLIC_URL,
  VERSION: version,
};

export const GOOGLE_KEY = 'AIzaSyBgMi_tKS5WRdH_RrCmdApIdCQCEZRIOyE';
