import { getHeaders } from '@utility/helperFunction';
import { orderBy } from 'lodash';

export const LOYALTY_ACCOUNT_LOOKUP_REQUESTED = 'loyalty/LOYALTY_ACCOUNT_LOOKUP_REQUESTED';
export const LOYALTY_ACCOUNT_LOOKUP_SUCCESS = 'loyalty/LOYALTY_ACCOUNT_LOOKUP_SUCCESS';
export const LOYALTY_ACCOUNT_LOOKUP_FAILURE = 'loyalty/LOYALTY_ACCOUNT_LOOKUP_FAILURE';

export const LOYALTY_ACCOUNT_ASSIGN_REQUESTED = 'loyalty/LOYALTY_ACCOUNT_ASSIGN_REQUESTED';
export const LOYALTY_ACCOUNT_ASSIGN_SUCCESS = 'loyalty/LOYALTY_ACCOUNT_ASSIGN_SUCCESS';
export const LOYALTY_ACCOUNT_ASSIGN_FAILURE = 'loyalty/LOYALTY_ACCOUNT_ASSIGN_FAILURE';

export const LOYALTY_SIGNUP_REQUESTED = 'loyalty/LOYALTY_SIGNUP_REQUESTED';
export const LOYALTY_SIGNUP_SUCCESS = 'loyalty/LOYALTY_SIGNUP_SUCCESS';
export const LOYALTY_SIGNUP_FAILURE = 'loyalty/LOYALTY_SIGNUP_FAILURE';

export const LOYALTY_SIGNIN_REQUESTED = 'loyalty/LOYALTY_SIGNIN_REQUESTED';
export const LOYALTY_SIGNIN_SUCCESS = 'loyalty/LOYALTY_SIGNIN_SUCCESS';
export const LOYALTY_SIGNIN_FAILURE = 'loyalty/LOYALTY_SIGNIN_FAILURE';

export const LOYALTY_GET_OFFERS_REQUESTED = 'loyalty/LOYALTY_GET_OFFERS_REQUESTED';
export const LOYALTY_GET_OFFERS_SUCCESS = 'loyalty/LOYALTY_GET_OFFERS_SUCCESS';
export const LOYALTY_GET_OFFERS_FAILURE = 'loyalty/LOYALTY_GET_OFFERS_FAILURE';

export const LOYALTY_VALIDATE_OFFER_REQUESTED = 'loyalty/LOYALTY_VALIDATE_OFFER_REQUESTED';
export const LOYALTY_VALIDATE_OFFER_SUCCESS = 'loyalty/LOYALTY_VALIDATE_OFFER_SUCCESS';
export const LOYALTY_VALIDATE_OFFER_FAILURE = 'loyalty/LOYALTY_VALIDATE_OFFER_FAILURE';

export const LOYALTY_APPLY_OFFER_REQUESTED = 'loyalty/LOYALTY_APPLY_OFFER_REQUESTED';
export const LOYALTY_APPLY_OFFER_SUCCESS = 'loyalty/LOYALTY_APPLY_OFFER_SUCCESS';
export const LOYALTY_APPLY_OFFER_FAILURE = 'loyalty/LOYALTY_APPLY_OFFER_FAILURE';

export const LOYALTY_ACCOUNT_SIGNUP_REQUESTED = 'loyalty/LOYALTY_ACCOUNT_SIGNUP_REQUESTED';
export const LOYALTY_ACCOUNT_SIGNUP_SUCCESS = 'loyalty/LOYALTY_ACCOUNT_SIGNUP_SUCCESS';
export const LOYALTY_ACCOUNT_SIGNUP_FAILURE = 'loyalty/LOYALTY_ACCOUNT_SIGNUP_FAILURE';

export const DELETE_LOYALTY_ACCOUNT_REQUESTED = 'loyalty/DELETE_LOYALTY_ACCOUNT_REQUESTED';
export const DELETE_LOYALTY_ACCOUNT_SUCCESS = 'loyalty/DELETE_LOYALTY_ACCOUNT_SUCCESS';
export const DELETE_LOYALTY_ACCOUNT_FAILURE = 'loyalty/DELETE_LOYALTY_ACCOUNT_FAILURE';

export const CLEAR_LOYALTY_OFFERS = 'loyalty/CLEAR_LOYALTY_OFFERS';

export const CLEAR_SELECTED_OFFERS = 'loyalty/CLEAR_SELECTED_OFFERS';

export const LOYALTY_APPLYOFFER_REQUESTED = 'loyalty/LOYALTY_APPLYOFFER_REQUESTED';
export const LOYALTY_APPLYOFFER_SUCCESS = 'loyalty/LOYALTY_APPLYOFFER_SUCCESS';
export const LOYALTY_APPLYOFFER_FAILURE= 'loyalty/LOYALTY_APPLYOFFER_FAILURE';

export const LOYALTY_DELETEOFFER_REQUESTED = 'loyalty/LOYALTY_DELETEOFFER_REQUESTED';
export const LOYALTY_DELETEOFFER_SUCCESS = 'loyalty/LOYALTY_DELETEOFFER_SUCCESS';
export const LOYALTY_DELETEOFFER_FAILURE = 'loyalty/LOYALTY_DELETEOFFER_FAILURE';

export const LOYALTY_INFO_SUCCESS = 'loyalty/LOYALTY_INFO_SUCCESS';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  loyaltyOffers: null,
  groupedLoyaltyOffers: {},
  accountLookup: null,
  offerCouponGiftCard: {},
  SingedInloyaltyInfo: { phoneNumber: null, email: null, type: null },
};

const allOffersSection = (action) => {
  const groupedLoyaltyOffers = {};
  const sortedOffers = orderBy(action.result?.loyalty?.offers, [(offer) => offer.groupSortOrder], ['asc']);
  (sortedOffers || []).forEach((offer) => {
    groupedLoyaltyOffers["OFFERS"] = {
      groupName: "OFFERS",
      offers: [...(groupedLoyaltyOffers["OFFERS"]?.offers || []), offer]
    };
  });
  return groupedLoyaltyOffers;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SELECTED_OFFERS: {
      return {
        ...state,
        offerCouponGiftCard: {},
      };
    }
    case LOYALTY_ACCOUNT_LOOKUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        accountLookup: null,
      };
    }
    case LOYALTY_ACCOUNT_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        accountLookup: action.result,
      };
    }
    case LOYALTY_ACCOUNT_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_SIGNUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_SIGNUP_SUCCESS: {
      let groupedLoyaltyOffers = allOffersSection(action);
      // console.log(groupedLoyaltyOffers, 'groupedLoyaltyOffers');
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loyaltyOffers: action.result.loyalty,
        groupedLoyaltyOffers,
      };
    }
    case LOYALTY_SIGNUP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_DELETEOFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_DELETEOFFER_SUCCESS: {
      const offerCouponGiftCard = {
        offer: action.result.paymentCheck.offer,
      };
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        offerCouponGiftCard,
      };
    }
    case LOYALTY_DELETEOFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_SIGNIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_SIGNIN_SUCCESS: {
      let groupedLoyaltyOffers = allOffersSection(action);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loyaltyOffers: action.result.loyalty,
        groupedLoyaltyOffers,
      };
    }
    case LOYALTY_SIGNIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_APPLYOFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_APPLYOFFER_SUCCESS: {
      const offerCouponGiftCard = {
        offer: action.result.paymentCheck.offer,
      };
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        offerCouponGiftCard,
      };
    }
    case LOYALTY_APPLYOFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_ACCOUNT_ASSIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_ACCOUNT_ASSIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_ACCOUNT_ASSIGN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_GET_OFFERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_GET_OFFERS_SUCCESS: {
      const groupedLoyaltyOffers = {};
      const sortedOffers = orderBy(
        action.result?.offers,
        [offer => offer.groupSortOrder],
        ['asc']
      );
      (sortedOffers || []).forEach(offer => {
        groupedLoyaltyOffers[offer.groupName] = {
          groupName: offer.groupName,
          offers: [
            ...(groupedLoyaltyOffers[offer.groupName]?.offers || []),
            offer,
          ],
        };
      });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loyaltyOffers: action.result,
        groupedLoyaltyOffers,
      };
    }
    case LOYALTY_GET_OFFERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_VALIDATE_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_VALIDATE_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_VALIDATE_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_APPLY_OFFER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_APPLY_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_APPLY_OFFER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_ACCOUNT_SIGNUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOYALTY_ACCOUNT_SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOYALTY_ACCOUNT_SIGNUP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_LOYALTY_ACCOUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_LOYALTY_ACCOUNT_SUCCESS: {
      window.sessionStorage.removeItem('loyaltyData');
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        offerCouponGiftCard: {},
      };
    }
    case DELETE_LOYALTY_ACCOUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CLEAR_LOYALTY_OFFERS:
      return {
        ...state,
        groupedLoyaltyOffers: {},
        loyaltyOffers: null,
        accountLookup: null,
      };
      case LOYALTY_INFO_SUCCESS: {
        return {
          ...state,
          SingedInloyaltyInfo: action.payload
        }
      }
    default:
      return state;
  }
};

export const loyaltyAccountLookup = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_ACCOUNT_LOOKUP_REQUESTED, LOYALTY_ACCOUNT_LOOKUP_SUCCESS, LOYALTY_ACCOUNT_LOOKUP_FAILURE],
    promise: (client) => client.post('loyaltyAccountLookup', {
      data,
      headers
    }),
  };
};

export const loyaltyAccountAssign = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_ACCOUNT_ASSIGN_REQUESTED, LOYALTY_ACCOUNT_ASSIGN_SUCCESS, LOYALTY_ACCOUNT_ASSIGN_FAILURE],
    promise: (client) => client.post('loyaltyAccountAssign', {
      data,
      headers
    }),
  };
};

export const loyaltySignUp = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_SIGNUP_REQUESTED, LOYALTY_SIGNUP_SUCCESS, LOYALTY_SIGNUP_FAILURE],
    promise: (client) => client.post('loyaltySignUp', {
      data,
      headers
    }),
  };
};

export const loyaltySignIn = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_SIGNIN_REQUESTED, LOYALTY_SIGNIN_SUCCESS, LOYALTY_SIGNIN_FAILURE],
    promise: (client) => client.post('loyaltySignIn', {
      data,
      headers
    }),
  };
};

export const loyaltyGetOffers = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_GET_OFFERS_REQUESTED, LOYALTY_GET_OFFERS_SUCCESS, LOYALTY_GET_OFFERS_FAILURE],
    promise: (client) => client.post('loyaltyGetOffers', {
      data,
      headers
    }),
  };
};

export const loyaltyValidateOffer = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_VALIDATE_OFFER_REQUESTED, LOYALTY_VALIDATE_OFFER_SUCCESS, LOYALTY_VALIDATE_OFFER_FAILURE],
    promise: (client) => client.post('loyaltyValidateOffer', {
      data,
      headers
    }),
  };
};

export const loyaltyApplyOffer = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_APPLY_OFFER_REQUESTED, LOYALTY_APPLY_OFFER_SUCCESS, LOYALTY_APPLY_OFFER_FAILURE],
    promise: (client) => client.post('loyaltyApplyOffer', {
      data,
      headers
    }),
  };
};

export const loyaltyAccountSignUp = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_ACCOUNT_SIGNUP_REQUESTED, LOYALTY_ACCOUNT_SIGNUP_SUCCESS, LOYALTY_ACCOUNT_SIGNUP_FAILURE],
    promise: (client) => client.post('loyaltyAccountSignUp', {
      data,
      headers
    }),
  };
};

export const deleteLoyaltyAccount = (data) => {
  const headers = getHeaders();
  return {
    types: [DELETE_LOYALTY_ACCOUNT_REQUESTED, DELETE_LOYALTY_ACCOUNT_SUCCESS, DELETE_LOYALTY_ACCOUNT_FAILURE],
    promise: (client) => client.post('deleteLoyaltyAccount', {
      data,
      headers
    }),
  };
};

export const clearLoyaltyOffers = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LOYALTY_OFFERS
    });
  };
};

export const applyLoyaltyOffer = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_APPLYOFFER_REQUESTED, LOYALTY_APPLYOFFER_SUCCESS, LOYALTY_APPLYOFFER_FAILURE],
    promise: (client) => client.post('applyLoyaltyOffer', {
      data,
      headers
    }),
  };
};

export const handleDeleteOffer = (data) => {
  const headers = getHeaders();
  return {
    types: [LOYALTY_DELETEOFFER_REQUESTED, LOYALTY_DELETEOFFER_SUCCESS, LOYALTY_DELETEOFFER_FAILURE],
    promise: (client) => client.post('handleDeleteOffer', {
      data,
      headers
    }),
  };
};

export const clearSelectedOffers = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_OFFERS
    });
  };
};

export const handleLoyaltyInfo = (data) => {
  return (dispatch) => {
    dispatch({
      type: LOYALTY_INFO_SUCCESS,
      payload: data
    })
  }
}